import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby"

export default ({title, bottom = false}) => (
    <StaticQuery 
        query = {graphql`
            query {
                palma: file(relativePath: { eq: "palemka.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                palma_prawa: file(relativePath: { eq: "palemka2.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                palma_poziom: file(relativePath: { eq: "palemka_poziom.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}

        render = { data => (
            <>
                    <div className={`section__title ${bottom ? "section__title-bottom" : ""}`}>
                        <Img className="section__image section__image-left" fluid={data.palma.childImageSharp.fluid} alt=""/>
                        <div className="section__title__content">
                            <h1>{title}</h1>
                        </div>
                        <Img className="section__image section__image-right" fluid={data.palma_prawa.childImageSharp.fluid} alt=""/>
                        <Img className="section__image section__image-bottom" fluid={data.palma_poziom.childImageSharp.fluid} alt=""/>
                    </div>
            </>
        )}
    />
);