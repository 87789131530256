import React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

import "../assets/scss/www.scss"

const HomeLayout = ({data, children}) => (
  <>
    <Header image={data} />
      {children}
    <Footer image={data} />
  </>
)

export default HomeLayout
