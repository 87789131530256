import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    swipeToSlide: true,
    fade: true
};

export default () => (
    <StaticQuery 
        query={graphql`
            query {
                s1: file(relativePath: { eq: "slider/s1.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                },
                s2: file(relativePath: { eq: "slider/s2.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                },
                s3: file(relativePath: { eq: "slider/s3.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                },
                s4: file(relativePath: { eq: "slider/s4.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                },
                s5: file(relativePath: { eq: "slider/s5.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}

        render={data=>(
            <Slider {...settings}>
                <div className="hero__slide">
                    <Img className="hero__slide__image" fluid={data.s1.childImageSharp.fluid} alt="ABRAM"/>
                    <div className="hero__slide__container">
                        <div className="container">
                            <h1 className="hero__slide__desc">
                                Dom pogrzebowy ABRAM
                            </h1>
                            <h2>Okólna 29, Ostrów Wlkp.</h2>
                        </div>
                    </div>
                </div>
                <div className="hero__slide">
                    <Img className="hero__slide__image" fluid={data.s2.childImageSharp.fluid} alt="ABRAM"/>
                    <div className="hero__slide__container">
                        <div className="container">
                            <h1 className="hero__slide__desc">
                                Kaplica w domu pogrzebowym
                            </h1>
                            <h2>Okólna 29, Ostrów Wlkp.</h2>
                        </div>
                    </div>
                </div>
                <div className="hero__slide">
                    <Img className="hero__slide__image" fluid={data.s3.childImageSharp.fluid} alt="ABRAM"/>
                </div>
                <div className="hero__slide">
                    <Img className="hero__slide__image" fluid={data.s4.childImageSharp.fluid} alt="ABRAM"/>
                </div>
                <div className="hero__slide">
                    <Img className="hero__slide__image" fluid={data.s5.childImageSharp.fluid} alt="ABRAM"/>
                </div>
            </Slider>
        )}
    />
);